import alliances from '../assets/icons/ODS/alliances.png';
import education from '../assets/icons/ODS/education.png';
import energy from '../assets/icons/ODS/energy.png';
import gender_equality from '../assets/icons/ODS/gender-equality.png';
import health from '../assets/icons/ODS/health.png';
import hunger from '../assets/icons/ODS/hunger.png';
import industry from '../assets/icons/ODS/industry.png';
import inequalities from '../assets/icons/ODS/inequalities.png';
import peace from '../assets/icons/ODS/peace.png';
import poverty from '../assets/icons/ODS/poverty.png';
import production from '../assets/icons/ODS/production.png';
import sustainable from '../assets/icons/ODS/sustainable.png';
import terrestrial_eco from '../assets/icons/ODS/terrestrial-eco.png';
import undersea from '../assets/icons/ODS/undersea.png';
import water from '../assets/icons/ODS/water.png';
import weather from '../assets/icons/ODS/weather.png';
import work from '../assets/icons/ODS/work.png';
import iconalliances from '../assets/icons/ODS/icons/alliances.png';
import iconeducation from '../assets/icons/ODS/icons/education.png';
import iconenergy from '../assets/icons/ODS/icons/energy.png';
import icongender_equality from '../assets/icons/ODS/icons/gender_equality.png';
import iconhealth from '../assets/icons/ODS/icons/health.png';
import iconhunger from '../assets/icons/ODS/icons/hunger.png';
import iconindustry from '../assets/icons/ODS/icons/industry.png';
import iconinequalities from '../assets/icons/ODS/icons/inequalities.png';
import iconpeace from '../assets/icons/ODS/icons/peace.png';
import iconpoverty from '../assets/icons/ODS/icons/poverty.png';
import iconproduction from '../assets/icons/ODS/icons/production.png';
import iconsustainable from '../assets/icons/ODS/icons/sustainable.png';
import iconterrestrial_eco from '../assets/icons/ODS/icons/terrestrial_eco.png';
import iconundersea from '../assets/icons/ODS/icons/undersea.png';
import iconwater from '../assets/icons/ODS/icons/water.png';
import iconweather from '../assets/icons/ODS/icons/weather.png';
import iconwork from '../assets/icons/ODS/icons/work.png';

import config from './routes';

const customs = {
  COMPANY: {
    SHORT_NAME: 'SHARYCO',
    LONG_NAME: 'SHARYCO'
  },
  SUPPORTED_LANGUAGES: ['en', 'es'],
  DEFAULT_LANGUAGE: 'es',
  MONEY_SYMBOL: '$',
  MONEY_CODE: 'u$s',
  MONEY_POSITION: 'prefix', //'sufix'
  MONEY_SPACE: true,
  CURRENCIES: ['USD', 'ARS'],
  CURRENCY: 'ARS',
  LOCALE: 'es-ES',
  USER_TYPE: {
    REGULAR: 'regular',
    ADVANCED: 'advanced'
  },
  USER_ACCOUNTS_INTEGRATED: true,
  USER_ACCOUNTS: {
    TYPES: {
      OWNER: 'owner',
      GUEST: 'guest'
    },
    STATUS: {
      ACTIVE: 'active',
      INACTIVE: 'inactive',
      REVOKED: 'revoked'
    }
  },
  ROLES: {
    ADMIN: 'admin',
    BENEFITS: 'benefits',
    INITIATIVES: 'initiatives',
    COMPANIES: 'companies'
  },
  OWNER: {
    SYSTEM: 'system'
  },
  POINTS: {
    leyend: 'lts',
    factor: 1000
  },
  VALUE_RANGE_ICONS: {
    POINTS: {
      LOW: { value: 2, icon: 'impact1' },
      MEDIUM: { value: 5, icon: 'impact3' },
      HIGH: { value: 10, icon: 'impact5' }
    },
    COINS: {
      LOW: { value: 100, icon: 'sharypoints_main' },
      MEDIUM: { value: 200, icon: 'sharypoints_main' },
      HIGH: { value: 500, icon: 'sharypoints_main' }
    }
  },

  COMPANY_SETTINGS: [
    { name: "Habilitar/deshabilitar onBoarding", code: "show_onboarding", details: "Esta opción permitirá habilitar/deshabilitar el módulo Onboarding de la app", value: false },
    { name: "Premio para el onBoarding", code: "prize_by_company", details: "Esta opcion permite elegir cual es el premio que se entregara una vez que finalicen con el onboarding", value: 0 },
    { name: "Acciones Sostenibles", code: "show_actions", details: "Esta opción permitirá habilitar/deshabilitar el módulo Acciones Sostenibles de la app", value: false },
    { name: "Beneficios", code: "show_benefits", details: "Esta opción permitirá habilitar/deshabilitar el módulo Beneficios de la app", value: false },
    { name: "Economía Circular", code: "show_offers", details: "Esta opción permitirá habilitar/deshabilitar el módulo Economía Circular de la app", value: false },
    { name: "Premios", code: "show_prizes", details: "Esta opción permitirá habilitar/deshabilitar el módulo Premios de la app", value: false },
  ],
  
  TYPES: {
    BENEFITS: {
      PRIZE: 'prize',
      MASLOW: 'maslow'
    },
    CATEGORIES: {
      BENEFITS: 'benefits'
    },
    PRODUCTS: 'services',
    SETTINGS: {
      SHARYCO_SETTINGS: 'sharyco_settings',
      SHARYCO_ADMIN_SETTINGS: 'sharyco_admin_settings',
      CODES: {
        SHARYCO_LEVELS: 'sharyco_levels'
      },
    },
    MESSAGES: {
      COMMENTS: 'comments',
      NOTIFICATION: 'notification',
    },
    TRANSACTIONS: {
      INITIATIVE: 'initiative',
      BENEFIT: 'benefit'
    },
    INITIATIVES: {
      DONATIONS:'donations',
      RECYCLING:'recycling',
      VOLUNTEERING:'volunteering'
    },
    SUBSIDIARIES: 'subsidiaries',
    GROUP: 'group'

  },
  TRANSACTIONS_TYPES: [
    { label: 'Préstamos', value: 'borrow' },
    { label: 'Acciones sostenibles', value: 'action' },
    { label: 'Servicio comunitario', value: 'initiative' },
    { label: 'Solicitud', value: 'request' },
    { label: 'Beneficios', value: 'benefit' },
    { label: 'Premios', value: 'prize' },
  ],
  TRANSACTIONS: {
    BORROW: {
      TYPE: 'borrow',
      SUB_TYPE: {
        FAVOR: 'favor',
        PRODUCT: 'product',
        SERVICE: 'service'
      },
      STATUS: {
        NEW: 'new', // "getting borrowed" (pedir prestado)
        ACCEPTED: 'accepted', //"owner accept the loan" (aceptar)
        CANCELED: 'canceled', //"owner or user cancel the loan" (cancelar/rechazar)
        ON_USER: 'on_user', // user gets the product (recibi)
        ON_OWNER: 'on_owner', // user send the product back to the owner (devolvi)
        REVIEW: 'review', //??
        ARCHIVED: 'archived', //
        COMPLETED: 'completed' // owner gets the product back (confirma devolucion)
      }
    },
    ACTION: {
      TYPE: 'action',
      SUB_TYPE: {
        TRANSPORT: 'transport',
        FOOD: 'food',
        HOME: 'home',
        ENERGY: 'energy',
        OUTDOORS: 'outdoors'
      },
      STATUS: {
        COMPLETED: 'completed'
      }
    },
    INITIATIVE: {
      TYPE: 'initiative',
      SUB_TYPE: {
        DONATIONS: 'donations',
        RECYCLING: 'recycling',
        VOLUNTEERING: 'volunteering'
      },
      STATUS:{
        POSTULATED: 'postulated',
        CANCELED: 'canceled',
        ATTENDED: 'attended',
        PENDING: 'pending', //attended to volunteering but no confirmed
      }
    },
    REQUEST: {
      TYPE: 'request',
      SUB_TYPE: {
        FAVOR: 'favor',
        PRODUCT: 'product',
        SERVICE: 'service'
      }
    },
    BENEFIT: {
      TYPE:'benefit',
      SUB_TYPE: {
        USER:'user',
        BUSINESS:'business',
      },
      STATUS:{
        GENERATED: 'generated',
        CONSUMED: 'consumed',
        CANCELED: 'canceled',
      },
    },
  },
  OPTIONS: {
    CATEGORIES: {
      TYPES: [
        { label: 'Productos', value: 'products' },
        { label: 'Favores', value: 'favors' },
        { label: 'Acciones', value: 'actions' },
        { label: 'Beneficios', value: 'benefits' }
      ]
    },

    COUNTRIES: ['argentina'],
    COMPANIES: {
      TYPES: ['benefits', 'initiatives']
    },
    INITIATIVES: {
      TYPES: ['volunteering', 'recycling', 'donations'],
      VOLUNTEERING: 'volunteering',
      RECYCLING: 'recycling',
      DONATIONS: 'donations'
    },
    PERMISSIONS: {
      OBJECT_TYPE: ['object example 1', 'object example 2', 'object example 3'],
      ACTION_TYPE: ['action example 1', 'action example 2', 'action example 3']
    },
    COUNTRIES: [
      'afghanistan',
      'albania',
      'algeria',
      'andorra',
      'angola',
      'antarctica',
      'antigua and barbuda',
      'argentina',
      'armenia',
      'australia',
      'austria',
      'azerbaijan',
      'bahamas',
      'bahrain',
      'bangladesh',
      'barbados',
      'belarus',
      'belgium',
      'belize',
      'benin',
      'bermuda',
      'bhutan',
      'bolivia',
      'bosnia and herzegovina',
      'botswana',
      'brazil',
      'brunei',
      'bulgaria',
      'burkina faso',
      'burma',
      'burundi',
      'cambodia',
      'cameroon',
      'canada',
      'cape Verde',
      'central african republic',
      'chad',
      'chile',
      'china',
      'colombia',
      'comoros',
      'congo, democratic republic',
      'congo, republic of the',
      'costa rica',
      "cote d'ivoire",
      'croatia',
      'cuba',
      'cyprus',
      'czech republic',
      'denmark',
      'djibouti',
      'dominica',
      'dominican republic',
      'east timor',
      'ecuador',
      'egypt',
      'el salvador',
      'equatorial guinea',
      'eritrea',
      'estonia',
      'ethiopia',
      'fiji',
      'finland',
      'france',
      'gabon',
      'gambia',
      'georgia',
      'germany',
      'ghana',
      'greece',
      'greenland',
      'grenada',
      'guatemala',
      'guinea',
      'guinea-bissau',
      'guyana',
      'haiti',
      'honduras',
      'hong kong',
      'hungary',
      'iceland',
      'india',
      'indonesia',
      'iran',
      'iraq',
      'ireland',
      'israel',
      'italy',
      'jamaica',
      'japan',
      'jordan',
      'kazakhstan',
      'kenya',
      'kiribati',
      'korea, north',
      'korea, south',
      'kuwait',
      'kyrgyzstan',
      'laos',
      'latvia',
      'lebanon',
      'lesotho',
      'liberia',
      'libya',
      'liechtenstein',
      'lithuania',
      'luxembourg',
      'macedonia',
      'madagascar',
      'malawi',
      'malaysia',
      'maldives',
      'mali',
      'malta',
      'marshall islands',
      'mauritania',
      'mauritius',
      'mexico',
      'micronesia',
      'moldova',
      'mongolia',
      'morocco',
      'monaco',
      'mozambique',
      'namibia',
      'nauru',
      'nepal',
      'netherlands',
      'new zealand',
      'nicaragua',
      'niger',
      'nigeria',
      'norway',
      'oman',
      'pakistan',
      'panama',
      'papua new guinea',
      'paraguay',
      'peru',
      'philippines',
      'poland',
      'portugal',
      'qatar',
      'romania',
      'russia',
      'rwanda',
      'samoa',
      'san marino',
      'sao tome',
      'saudi arabia',
      'senegal',
      'serbia and montenegro',
      'seychelles',
      'sierra leone',
      'singapore',
      'slovakia',
      'slovenia',
      'solomon islands',
      'somalia',
      'south africa',
      'spain',
      'sri lanka',
      'sudan',
      'surinam',
      'swaziland',
      'sweden',
      'switzerland',
      'syria',
      'taiwan',
      'tajikistan',
      'tanzania',
      'thailand',
      'togo',
      'tonga',
      'trinidad and tobago',
      'tunisia',
      'turkey',
      'turkmenistan',
      'uganda',
      'ukraine',
      'united arab emirates',
      'united kingdom',
      'united states',
      'uruguay',
      'uzbekistan',
      'vanuatu',
      'venezuela',
      'vietnam',
      'yemen',
      'zambia',
      'zimbabwe'
    ], 

    COUNTRIES_ES: [
      "afganistan",
      "albania",
      "alemania",
      "andorra",
      "angola",
      "antartida",
      "antigua y barbuda",
      "arabia saudita",
      "argelia",
      "argentina",
      "armenia",
      "australia",
      "austria",
      "azerbaiyan",
      "bahamas",
      "banglades",
      "barbados",
      "barein",
      "belice",
      "benin",
      "bielorrusia",
      "birmania",
      "bolivia",
      "bosnia y herzegovina",
      "botsuana",
      "brasil",
      "brunei",
      "bulgaria",
      "burkina faso",
      "burundi",
      "butan",
      "belgica",
      "cabo verde",
      "camboya",
      "camerun",
      "canada",
      "chad",
      "chile",
      "china",
      "chipre",
      "colombia",
      "comoras",
      "corea del norte",
      "corea del sur",
      "costa de marfil",
      "costa rica",
      "croacia",
      "cuba",
      "dinamarca",
      "dominica",
      "ecuador",
      "egipto",
      "el salvador",
      "emiratos arabes unidos",
      "eritrea",
      "eslovaquia",
      "eslovenia",
      "espana",
      "estados unidos",
      "estonia",
      "etiopia",
      "fiyi",
      "filipinas",
      "finlandia",
      "francia",
      "gabon",
      "gambia",
      "georgia",
      "ghana",
      "granada",
      "grecia",
      "groenlandia",
      "guatemala",
      "guinea",
      "guinea-bisau",
      "guinea ecuatorial",
      "guyana",
      "haiti",
      "honduras",
      "hong kong",
      "hungria",
      "india",
      "indonesia",
      "irak",
      "iran",
      "irlanda",
      "islandia",
      "islas salomon",
      "islas marshall",
      "israel",
      "italia",
      "jamaica",
      "japon",
      "jordania",
      "kazajistan",
      "kenia",
      "kirguistan",
      "kiribati",
      "kuwait",
      "laos",
      "letonia",
      "lesoto",
      "liberia",
      "libia",
      "liechtenstein",
      "lituania",
      "luxemburgo",
      "libano",
      "macedonia",
      "madagascar",
      "malasia",
      "malaui",
      "maldivas",
      "mali",
      "malta",
      "marruecos",
      "mauricio",
      "mauritania",
      "micronesia",
      "moldavia",
      "monaco",
      "mongolia",
      "mozambique",
      "mexico",
      "namibia",
      "nauru",
      "nepal",
      "nicaragua",
      "niger",
      "nigeria",
      "noruega",
      "nueva zelanda",
      "oman",
      "paises bajos",
      "pakistan",
      "panama",
      "papua nueva guinea",
      "paraguay",
      "peru",
      "polonia",
      "portugal",
      "reino unido",
      "republica checa",
      "republica democratica del congo",
      "republica del congo",
      "republica dominicana",
      "ruanda",
      "rumania",
      "rusia",
      "samoa",
      "san marino",
      "santo tome y principe",
      "senegal",
      "serbia y montenegro",
      "seychelles",
      "sierra leona",
      "singapur",
      "siria",
      "somalia",
      "sri lanka",
      "suazilandia",
      "sudafrica",
      "sudan",
      "suecia",
      "suiza",
      "surinam",
      "tailandia",
      "taiwan",
      "tayikistan",
      "tanzania",
      "togo",
      "tonga",
      "trinidad y tobago",
      "turkmenistan",
      "turquia",
      "tunez",
      "ucrania",
      "uganda",
      "uruguay",
      "uzbekistan",
      "vanuatu",
      "venezuela",
      "vietnam",
      "yemen",
      "yibuti",
      "zambia",
      "zimbabue"
  ],

    STATES: {
      ARGENTINA: [
        'buenos aires', 'catamarca', 'chaco', 'chubut', 'ciudad autónoma de buenos aires', 'córdoba', 'corrientes', 'entre ríos', 'formosa', 'jujuy', 'la pampa', 'la rioja', 'mendoza', 'misiones', 'neuquén', 'río negro', 'salta', 'san juan', 'san luis', 'santa cruz', 'santa fe', 'santiago del estero', 'tierra del fuego', 'tucumán'
      ]
    },
    TAX_TYPES: ['A', 'B', 'C'],
    TIME_ZONES: [
      {
        value: 'GMT-12:00',
        label: 'Etc/GMT-12'
      },
      {
        value: 'GMT-11:00',
        label: 'Pacific/Midway'
      },
    ],
    USER_TYPES: ['regular', 'advanced', 'patient', 'professional', 'pharmacy'],
    SHARYCO_ROLES: ['benefits', 'initiatives', 'companies'],
    SIGNUP: {
      IDENTIFICATION: {
        TYPES: ['DNI', 'Pasaporte', 'Libreta de enrolamiento', "Documento de Identidad"]
      },
      GENDER: {
        TYPES: ['Hombre', 'Mujer']
      }
    }
  },
  SIDEBAR: {
    MENU: {
      MAIN: [{ icon: 'home', label: 'Panel', route: config.ROUTES.HOME }],
      SECONDARY: [],
      ADMIN: [
        { label: 'Administration' },
        // {
        //   icon: 'receipt_tax',
        //   label: 'Beneficios Empresa',
        //   route: config.ROUTES.BENEFITS_LIST
        // },
        {
          label: 'Desafíos',
          route: config.ROUTES.CHALLENGES,
          icon: 'challenges',
        },
        {
          icon: 'qrcode',
          label: 'Lectura de QR',
          route: config.ROUTES.BENEFITS_CONSUME
        },
        // {
        //   icon: 'qrcode',
        //   label: 'Lectura de QR',
        //   route: config.ROUTES.INITIATIVES_ATTENDANCE
        // },
        {
          icon: 'receipt_tax',
          label: 'Beneficios',
          route: config.ROUTES.BENEFITS
        },
        {
          icon: 'clipboard_list',
          label: 'Beneficios consumidos',
          route: config.ROUTES.BENEFITS_CONSUMPTIONS
        },
        // {
        //   icon: 'receipt_tax',
        //   label: 'Beneficios',
        //   route: config.ROUTES.BENEFITS
        // },
        {
          icon: 'office_building',
          label: 'companies',
          route: config.ROUTES.COMPANIES
        },
        {
          icon: 'office_building',
          label: 'my company',
          route: config.ROUTES.MY_COMPANY
        },
        {
          icon: 'briefcase',
          label: 'businesses',
          route: config.ROUTES.BUSINESSES
        },
        // {
        //   icon: 'briefcase',
        //   label: 'entities',
        //   route: config.ROUTES.ENTITIES
        // },
        // {
        //   icon: 'briefcase',
        //   label: 'subsidiaries',
        //   route: config.ROUTES.SUBSIDIARIES
        // },
        {
          icon: 'gift',
          label: 'prizes',
          route: config.ROUTES.PRIZES
        },
        {
          icon: 'briefcase',
          label: 'my business',
          route: config.ROUTES.MY_BUSINESS
        },
        {
          icon: 'briefcase',
          label: 'mi organización',
          route: config.ROUTES.MY_ORGANIZATION
        },
        {
          icon: 'clipboard_list',
          label: 'categories',
          route: config.ROUTES.CATEGORIES
        },
        {
          icon: 'globe',
          label: 'Administration' 
        },
        //{ icon: 'user_group', label: 'Users', route: config.ROUTES.COLLABORATORS },
        // { icon: 'user_group', label: 'Profesionales', route: config.ROUTES.PROFESSIONALS },
        // { icon: 'user_group', label: 'Farmacias', route: config.ROUTES.PHARMACIES },
        /* {
          icon: 'user_group',
          label: 'Accounts',
          route: config.ROUTES.ACCOUNTS,
        }, */
        { icon: 'hand_world', label: 'acciones sostenibles', route: config.ROUTES.ACTIONS },
        // {
        //   icon: 'community_service',
        //   label: 'Iniciativas de Servicio Comunitario',
        //   route: config.ROUTES.INITIATIVES
        // },
        {
          icon: 'chat_alt',
          label: 'Reclamos',
          route: config.ROUTES.MESSAGES
        },
        {
          icon: 'tag',
          label: 'productos',
          route: config.ROUTES.PRODUCTS
        },
        {
          icon: 'tag',
          label: 'Área',
          route: config.ROUTES.DEPARTMENTS
        },
        {
          icon: 'user_group_2',
          label: 'Colaboradores',
          route: config.ROUTES.COLLABORATORS
        },
        { 
          icon: 'user_group',
          label: 'Administradores',
          route: config.ROUTES.ADMINISTRATORS
        },
        {
          icon: 'switch_horizontal',
          label: 'Interacciones de Usuarios', //trasanctions
          route: config.ROUTES.TRANSACTIONS
        },
        // {
        //   icon: 'trophy',
        //   label: 'Ranking',
        //   route: config.ROUTES.RANKING
        // },
        {
          icon: 'coin24',
          label: 'Créditos',
          route: config.ROUTES.CREDITS_COMPANY
        },
        {
          icon: 'switch_horizontal',
          label: 'Consumos',
          route: config.ROUTES.CONSUMPTIONS
        },
        {
          icon: 'switch_horizontal',
          label: 'Participantes',
          route: config.ROUTES.ASSISTANCES
        },

        { icon: 'user_group', label: 'Referentes', route: config.ROUTES.REFERENTS },
        
        {
          icon: 'cog',
          label: 'Settings',
          route: config.ROUTES.MY_SETTINGS
        }
      ],
      EXTRAS: [
        { label: 'Information' },
        { icon: 'help', label: 'How works?', route: config.ROUTES.HOW_WORKS },
        { icon: 'phone', label: 'Contact us', route: config.ROUTES.CONTACT_US },
        { icon: '', label: 'Terms and conditions', route: config.ROUTES.TERMS }
      ]
    }
  },
  SLIDER: {
    SHOW: false,
    CONTENT: []
  },
  BENEFITS: {
    TYPES: [
      { label: 'Para empresas', value: 'company' },
      { label: 'Para usuarios', value: 'user' },
      { label: 'Ambos', value: 'both' },
    ],
    INTERVALS: [
      // TODO: traducir
      { label: "4 Horas", value: 4},
      { label: 'half a day', value: 12 },
      { label: 'one day', value: 24 },
      { label: 'one week', value: 168 },
      { label: 'one month', value: 720 }
    ],
    DISCOUNT_TYPES: [{ label: 'Porcentaje', value: 'percentage' }]
  }, 
  ACTIONS: {
    INTERVALS: [
      { label: "4 Horas", value: 4},
      { label: 'half a day', value: 12 },
      { label: 'one day', value: 24 },
      { label: 'one week', value: 168 },
      { label: 'one month', value: 720 },
      {label: 'seis meses', value: 4380},
      { label: 'uso único', value: 24*365*10 },
    ],
  },
  VOUCHERS: {
    INTERVALS: [
      { label: " 100 Créditos", value: 100},
      { label: '50 Créditos', value: 50 },
      { label: '25 Créditos', value: 25 },
      { label: '10 Créditos', value: 10 },
      { label: '5 Créditos', value: 5 },
      {label: '3 Créditos', value: 3},
    ],
  },
  ODS: [
    { squareIcon: poverty, icon: iconpoverty, code: '1', text: 'Poner fin a la pobreza en todas sus formas en todo el mundo' },
    { squareIcon: hunger, icon: iconhunger, code: '2', text: 'Poner fin al hambre' },
    { squareIcon: health, icon: iconhealth, code: '3', text: 'Garantizar una vida sana y promover el bienestar para todos en todas las edades' },
    { squareIcon: education, icon: iconeducation, code: '4', text: 'Garantizar una educación inclusiva, equitativa y de calidad y promover oportunidades de aprendizaje durante toda la vida para todos' },
    { squareIcon: gender_equality, icon: icongender_equality, code: '5', text: 'Lograr la igualdad entre los géneros y empoderar a todas las mujeres y las niñas' },
    { squareIcon: water, icon: iconwater, code: '6', text: 'Garantizar la disponibilidad de agua y su gestión sostenible y el saneamiento para todos' },
    { squareIcon: energy, icon: iconenergy, code: '7', text: 'Garantizar el acceso a una energía asequible, segura, sostenible y moderna' },
    { squareIcon: work, icon: iconwork, code: '8', text: 'Promover el crecimiento económico inclusivo y sostenible, el empleo y el trabajo decente para todos' },
    { squareIcon: industry, icon: iconindustry, code: '9', text: 'Construir infraestructuras resilientes, promover la industrialización sostenible y fomentar la innovación' },
    { squareIcon: inequalities, icon: iconinequalities, code: '10', text: 'Reducir la desigualdad en y entre los países' },
    { squareIcon: production, icon: iconproduction, code: '12', text: 'Garantizar modalidades de consumo y producción sostenibles' },
    { squareIcon: sustainable, icon: iconsustainable, code: '11', text: 'Lograr que las ciudades sean más inclusivas, seguras, resilientes y sostenibles' },
    { squareIcon: weather, icon: iconweather, code: '13', text: 'Adoptar medidas urgentes para combatir el cambio climático y sus efectos' },
    { squareIcon: undersea, icon: iconundersea, code: '14', text: 'Conservar y utilizar sosteniblemente los océanos, los mares y los recursos marinos' },
    { squareIcon: terrestrial_eco, icon: iconterrestrial_eco, code: '15', text: 'Gestionar sosteniblemente los bosques, luchar contra la desertificación, detener e invertir la degradación de las tierras, detener la pérdida de biodiversidad' },
    { squareIcon: peace, icon: iconpeace, code: '16', text: 'Promover sociedades justas, pacíficas e inclusivas' },
    { squareIcon: alliances, icon: iconalliances, code: '17', text: 'Revitalizar la Alianza Mundial para el Desarrollo Sostenible' }
  ],
  ACTIONS_SUB_TYPES: [
    { label: 'Normal', value: 'regular' },
    { label: 'Video con preguntas', value: 'questions' },
    { label: 'Video', value: 'video' } 
  ],
};
export default customs;
