import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import config from '../../../config';
import { history } from '../../../routes';
import { ToastContainer, notify } from '../../../libraries/notifications';
import { capitalize, isEmptyObject, validateIsfilled, selectGeneratorWObjChild, formatOnlyNumberInput, getOwner, replaceNewLines, validateURL, parseUrl, optionSelectGenerator, } from '../../../libraries/utils';
import FormLayout from '../../../components/forms/FormLayout';
import benefitsActions from '../../../context/benefits/actions';
import filesActions from '../../../context/files/actions';
import usersActions from '../../../context/users/actions';
import categoriesActions from '../../../context/categories/actions';
import businessesActions from '../../../context/businesses/actions';
import settingsActions from '../../../context/settings/actions';
import LayoutWithSidebar from '../../../components/layout/LayoutWithSidebar';
import PanelWindow from '../../../components/PanelWindow';
import TextInput from '../../../components/forms/TextInput';
import SelectInput from '../../../components/forms/SelectInput';
import ImageUploadFileInputEdit from '../../../components/forms/ImageUploadFileInputEdit';
import IntegerInput from '../../../components/forms/IntegerInput';
import TextareaInput from '../../../components/forms/TextareaInput';
import DatepickerInput from '../../../components/forms/DatepickerInput';
import arrayMutators from 'final-form-arrays';

class Benefit extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      benefitCategories: [],
      business: {},
      businesses: [],
      imageUpdated: false,
      creatingNew: false,
      editing: false,
      files: {},
      loading: false,
      remove: false,
      setting: null,
      submitting: false,
      titles: '',
      user: {},
      view: false
    };
    this.submit = null;
    this.reset = null;
    this.breadcrumbs = [this.t('Benefits'), this.t('New')];
    this.titles = this.t('Benefit details');
  }
  // ** Life cycle
  componentDidMount() {
    const params = this.props.match.params;
    if (params.id) {
      this.setState({
        remove: true,
        view: true,
        titles: this.t('Edit benefit')
      });
      this.breadcrumbs = [this.t('Benefits'), this.t('Edit')];
      this.getBenefit(params.id);
      this.getBussiness(params.id);
    } else {
      this.setState({ titles: this.t('New Benefit'), creatingNew: true });
    }
    this.getBussinesses();
    this.getBenefitCategories();
    this.getSettings();
  }

  getSettings = async () => {
    await this.props.onGetSettings({ code: config.TYPES.SETTINGS.CODES.SHARYCO_LEVELS });
    const { settings } = this.props;
    if (settings.error) {
      notify(this.t(settings.error.message));
    } else {
      this.setState({ setting: settings.items[0] });
    }
  };
  getBenefitCategories = async () => {
    await this.props.onGetBenefitCategories({ type: config.TYPES.CATEGORIES.BENEFITS });
    const { categories } = this.props;
    if (categories.error) {
      notify(this.t(categories.error.message));
    } else {
      this.setState({ benefitCategories: categories.items });
    }
  };

  getBenefit = async id => {
    this.setState({ loading: true });
    await this.props.onGetBenefit(id);
    const error = this.props.benefit.error;
    if (error) {
      notify(this.t(error.message));
    } else {
      this.setState({
        benefit: this.props.benefit.item,
        files: { picture: this.props.benefit.item.json_data?.picture },
        loading: this.props.benefit.loading
      });
    }
    this.setState({ loading: false });
  };

  componentWillUnmount() {
    this.props.onClearCurrent();
  }

  goBack = () => {
    history.push(config.ROUTES.BENEFITS);
  };

  getBussinesses = async () => {
    await this.props.onGetBusinesses();
    const { businesses } = this.props;
    if (businesses.error) {
      notify(this.t(businesses.error.message));
    } else {
      console.log("____busines", businesses.items)
      this.setState({ businesses: businesses.items });
    }
  };

  getBussiness = async id => {
    await this.props.onGetBusiness(id);
    const { business } = this.props;
    if (business.error) {
      notify(this.t(business.error.message));
    } else {
      this.setState({ business: business.item });
    }
  };

  onFileChangeImageInput = async ({ file, data, name }) => {
    const { creatingNew, benefit } = this.state;
    this.setState({ submitting: true });
    let imageIdentifier;
    if (creatingNew) {
      imageIdentifier = `${parseInt(Math.random() * 10 ** 9)}`;
      data.append('identifier', imageIdentifier);
    } else {
      data.append('identifier', `${benefit.id}_${name}`);
    }
    await this.props.onPictureUpload(data);
    const error = this.props.files.error;
    if (error) {
      notify(this.t(error.message));
    } else {
      this.setState({ submitting: false, files: {
          ...this.state.files,
          [name]: this.props.files.files.fileInfo.location
        }, });

      return this.props.files.files.fileInfo.location;
    }
  };

  onClearImageField = ({ name }) => {
    if (name) this.setState({ files: { ...this.state.files, [name]: null } });
  };

  onSubmit = async values => {
    // generar objeto data
    const data = {
      type: config.TYPES.BENEFITS.MASLOW, // maslow
      name: values.name, // nombre
      detail: replaceNewLines(values.detail), // detalle
      owner: this.props.auth.user.id, // dueño
      code: values.code, // pais donde está disponible
      enabled: Boolean(this.state.benefit?.enabled),
      pack: values.pack, // rubro
      json_data: {
        url: values.url,
        picture: this.state.files.picture,
        // owner_description: values.owner_description,
        credits: values.credits, // costo ==> INTENTAR MOVER FUERA DEL JSON_DATA
        rules: {
          start_date: values.start_date,
          end_date: values.end_date,
          user_uses: parseInt(values.user_uses), // maximo de usos por usuario
          user_uses_interval: parseInt(values.user_uses_interval), // intervalo de tiempo entre cada uso de usuario
        }
      }
    };

    // agregar id para que se edite
    if (values.id) {
      data.id = values.id;
    }

    await this.props.onSaveOrUpdate({ ...data });
    const benefit = this.props.benefit;
    if (benefit.error) {
      notify(this.t(benefit.error.message));
    } else {
      this.goBack();
    }
  };

  disableEnableBenefit = async () => {
    this.setState({loading: true})
    const { benefit } = this.state;
    const data = { id: benefit.id, enabled: benefit.enabled };
    await this.props.onSaveOrUpdate(data);
    const error = this.props.benefit.error;
    if (error) {
      notify(this.t(error.message));
      this.setState({ submitting: false });
    }
    this.setState({loading: false})
  };

  render() {
    const { benefitCategories, creatingNew, view, editing, submitting, benefit, loading, files, businesses, setting } = this.state;
    // const { roles } = this.props.auth.user;

    // generar objeto json sin anidado
    const flatJSON = { ...benefit?.json_data };

    // generar objeto rules sin anidado tras parsear el json
    const flatRules = { ...benefit?.json_data?.rules };

    ////////////////////////////////////////////////////////////
    //fixme: SE BORRA EL FORMULARIO DESPUES DE SUBIR LA IMAGEN//
    ////////////////////////////////////////////////////////////

    // crear el objeto privado (dif del estado)
    const _benefit = {};
    _benefit.valuesPorNivel = [];
    for (let i = 0; i < setting?.value; i++) {
      //console.log("___setting value", i, { nivel: i, type_of_discount: config.BENEFITS.DISCOUNT_TYPES[0].value })
      _benefit.valuesPorNivel.push({ nivel: i, type_of_discount: config.BENEFITS.DISCOUNT_TYPES[0].value });
    }

    // mapear benefit al objeto _benefit
    if (!isEmptyObject(benefit) && benefit !== undefined && benefit !== null) {
      for (let key of Object.keys(benefit)) {
        // se agregan las propiedades que no estaban nesteadas primero
        if (key !== 'json_data' && key !== 'rules') {
          _benefit[key] = benefit[key];
        }
      }

      // luego se cargan las propiedades desanidadas
      console.log("JSON",flatJSON)
      for (let jsonObject of Object.keys(flatJSON)) {
        _benefit[jsonObject] = flatJSON[jsonObject];
      }
      for (let rulesObject of Object.keys(flatRules)) {
        _benefit[rulesObject] = flatRules[rulesObject];
      }

      // se cargan las values
      if (!isEmptyObject(benefit.json_data.values)) {
        // si hay más de una se hace este array
        if (Object.keys(benefit.json_data.values).length > 0) _benefit.valuesPorNivel = [];
        for (let key of Object.keys(benefit.json_data.values)) {
          _benefit.valuesPorNivel?.push({
            value: benefit.json_data.values[key].value,
            type_of_discount: benefit.json_data.values[key].type
          });
        }
      } 
      // la coercion de tipo es voluntaria, porque puede venir un número o un string
      // por eso se desactiva el linter
      _benefit.unlimited_group_uses = false;
      if (
        benefit.json_data.rules &&
        benefit.json_data.rules.group_uses &&
        benefit.json_data.rules.group_uses_interval &&
        // eslint-disable-next-line
        benefit.json_data.rules.group_uses == 0 &&
        // eslint-disable-next-line
        benefit.json_data.rules.group_uses_interval == 0
      ) {
        _benefit.unlimited_group_uses = true;
      }

      _benefit.unlimited_user_uses = false;
      if (
        benefit.json_data.rules &&
        benefit.json_data.rules.user_uses_interval &&
        benefit.json_data.rules.user_uses &&
        // eslint-disable-next-line
        benefit.json_data.rules.user_uses_interval == 0 &&
        // eslint-disable-next-line
        benefit.json_data.rules.user_uses == 0
      ) {
        _benefit.unlimited_user_uses = true;
      }

      // se cargan estos valores
      _benefit.business = benefit.json_data.business && benefit.json_data.business.id;
      _benefit.type_of_discount = 'percentage';
    } else {
      _benefit.days_of_week = {
        l: true,
        m: true,
        x: true,
        j: true,
        v: true,
        s: true,
        d: true
      };

      
      _benefit.group_uses = 0;
      _benefit.user_uses = 0;
      _benefit.unlimited_group_uses = true;
      _benefit.unlimited_user_uses = true;
    }

    // options
    const categoryTypes = selectGeneratorWObjChild(benefitCategories, 'id', 'name');
    const countries = optionSelectGenerator(config.OPTIONS.COUNTRIES);
    //const filteredBusinesses = businesses.filter(elem => elem.owner === this.props.auth.user.id && elem.enabled);
    // const filteredBusinesses = businesses.filter(elem => elem.owner === getOwner(this.props.auth.user) && elem.enabled);
    // const businessesOptions = selectGeneratorWObjChild([config.ROLES.COMPANIES, config.ROLES.ADMIN].includes(roles[0]) ? businesses : filteredBusinesses, 'id', 'name');
    // const benefitsTypes = selectGeneratorWObjChild(config.BENEFITS.TYPES, 'value', 'label');
    // const possibleIntervals = selectGeneratorWObjChild(config.BENEFITS.INTERVALS, 'value', o => capitalize(this.t(o.label)));
    // const discountTypeOptions = selectGeneratorWObjChild(config.BENEFITS.DISCOUNT_TYPES, 'value', 'label');

    // Layout actions
    const actions = {
      main: {
        onClick: e => this.submit(e),
        title: this.t('Save'),
        icon: 'checkmark',
        disabled: submitting || (view && !editing),
        checkPermissions: 'insert'
      },
      secondaries: [
        {
          onClick: e => history.push(config.ROUTES.BENEFITS),
          title: this.t('Go Back'),
          icon: 'cheveron_left',
          disabled: submitting,
          visible: true,
          className: 'btn-accent'
        },

        {
          onClick: e => this.setState({ editing: true }),
          title: this.t('Edit'),
          icon: 'edit_pencil',
          disabled: submitting,
          visible: view && !editing,
          checkPermissions: 'update'
        },
        {
          onClick: e => {
            this.reset();
            this.setState({ editing: false });
          },
          title: this.t('Cancel'),
          icon: 'edit_pencil',
          disabled: submitting || !editing,
          visible: editing
        },
        {
          onClick: e => {
            if (benefit.enabled === 1)
              this.setState({ benefit: { ...benefit, enabled: 0 } }, () => this.disableEnableBenefit());
            if (benefit.enabled === 0)
              this.setState({ benefit: { ...benefit, enabled: 1 } }, () => this.disableEnableBenefit());
          },
          title: this.t(benefit?.enabled ? 'Disable' : 'Enable'),
          icon: !benefit?.enabled ? 'view_show' : 'view_hide',
          disabled: submitting || this.props.benefit.loading,
          checkPermissions: 'enable',
          visible: !creatingNew
        }
      ]
    };

    // ** Form validation functions
    const required = value =>
      validateIsfilled(value) || (view && !editing) ? undefined : this.t('This field is required');

    const validateForm = values => {
      const errors = {};
      errors.name = required(values.name);
      // errors.owner = required(values.owner); // todo: check
      errors.pack = required(values.pack);
      errors.detail = required(values.detail);
      return errors;
    };

    const isUrl = value => (validateURL(value) || (view && !editing) || !value) ? undefined : this.t('Ingresar URL válido');

    console.log("BENEFIT FORM", _benefit)

    // ** Actual render
    return (
      <LayoutWithSidebar
        main={{ className: 'text-content-400' }}
        header={{
          breadcrumbs: this.breadcrumbs
        }}
        container={{ className: 'px-8' }}
        actions={actions}>
        <ToastContainer />
        <PanelWindow outerTitle={this.titles} loading={loading}>
          {/*
              ////////////////////////////////////////////////////////////
              //fixme: SE BORRA EL FORMULARIO DESPUES DE SUBIR LA IMAGEN//
              ////////////////////////////////////////////////////////////
          */}
          <Form
            initialValues={(view || editing) ? (_benefit || {}) : _benefit}
            onSubmit={this.onSubmit}
            mutators={{
              ...arrayMutators
            }}
            validate={validateForm}>
            {({handleSubmit,form,submitting,pristine,values,form:{mutators: { push, pop }}}) => {
              this.submit = handleSubmit;
              this.reset = form.reset;
              return (
                <FormLayout form={form} onSubmit={this.onSubmit} values={values}>
                  <div className="w-full mb-3 md:mb-6">
                    {!editing && !creatingNew && values.picture && files ? (
                      <div className='flex justify-center items-center'>
                        <img src={`${files.picture}?t=${Math.random(5)}`} alt="" className="max-h-48 md:max-h-72 object-cover rounded-lg" />
                      </div>
                    ) : (
                      <Field
                        name="picture"
                        img={this.state.files.picture}
                        component={ImageUploadFileInputEdit}
                        label={capitalize(this.t('image'))}
                        placeholder={capitalize(this.t('image URL'))}
                        inputOnChange={this.onFileChangeImageInput}
                        disabled={view && !editing}
                        clickButtonClear={this.onClearImageField}
                        inputClassName="input-bordered shadow-none cursor-pointer"
                        noError={true}
                      />
                    )}
                  </div>
                  <div className="xl:grid xl:grid-cols-12 xl:grid-rows-1">
                    <Field
                      name="name"
                      component={TextInput}
                      placeholder={capitalize(this.t('name of benefit'))}
                      label={capitalize(this.t('name of benefit'))}
                      readOnly={view && !editing}
                      className="px-3 mb-3 md:mb-0 xl:col-span-5"
                    />
                    {/* <Field // EMPRESA
                      name="owner"
                      component={SelectInput}
                      label={capitalize(this.t('store'))}
                      placeholder={capitalize(this.t('store'))}
                      options={businessesOptions}
                      empty={capitalize(this.t('select a store'))}
                      readOnly={view && !editing}
                      className="px-3 mb-3 md:mb-0"
                    /> */}
                  </div>
                  <div className="xl:grid xl:grid-cols-6 xl:grid-rows-1 xl:gap-x-4">
                    <Field // RUBRO / CATEGORY
                      name="pack"
                      component={SelectInput}
                      label={capitalize(this.t('category'))}
                      placeholder={capitalize(this.t('category'))}
                      options={categoryTypes}
                      empty={capitalize(this.t('select a category'))}
                      readOnly={view && !editing}
                      className="xl:col-span-2 px-3 mb-3 md:mb-0"
                    />
                    <Field // PAIS / COUNTRY
                      name="code"
                      component={SelectInput}
                      label={capitalize(this.t('País'))}
                      placeholder={capitalize(this.t('País'))}
                      options={countries}
                      empty={capitalize(this.t('Elegí un país'))}
                      readOnly={view && !editing}
                      className="xl:col-span-2 px-3 mb-3 md:mb-0"
                    />
                  </div>
                  <Field
                    name="detail"
                    component={TextareaInput}
                    placeholder={capitalize(this.t('details'))}
                    label={capitalize(this.t('details'))}
                    readOnly={view && !editing}
                    className="px-3 mb-3 md:mb-0"
                  />
                  {/* <Field
                    name="owner_description"
                    component={TextareaInput}
                    placeholder={capitalize(this.t('detalles de la empresa'))}
                    label={capitalize(this.t('detalles de la empresa'))}
                    readOnly={view && !editing}
                    className="px-3 mb-3 md:mb-0"
                  /> */}
                  <Field
                    name="credits"
                    component={IntegerInput}
                    placeholder={capitalize(this.t('creditos'))}
                    label={capitalize(this.t('costo'))}
                    parse={v => formatOnlyNumberInput(v)}
                    readOnly={view && !editing}
                    className="ml-4"
                  />
                  <Field
                    name="url"
                    component={TextInput}
                    placeholder={capitalize(this.t('brand website'))}
                    label={capitalize(this.t('brand website'))}
                    readOnly={view && !editing}
                    className="xl:col-span-4 px-3 mb-3 md:mb-0"
                    parse={parseUrl} validate={isUrl}
                  />
                  <div>
                    <hr />
                    {/* Elimine el control de usos por colaborador */}
                    {/* <h3 className="px-3 mb-3 text-lg ml-3">{capitalize(this.t('Usos por colaborador'))}:</h3>
                    <div className="flex ml-8">
                      <Field
                        name="unlimited_user_uses"
                        component={CheckboxInput}
                        placeholder={capitalize(this.t('unlimited'))}
                        label={capitalize(this.t('unlimited'))}
                        readOnly={view && !editing}
                        type="checkbox"
                        labelClassName="mr-8"
                      />
                      <Field
                        name="user_uses"
                        component={IntegerInput}
                        placeholder={capitalize(this.t('uses'))}
                        label={capitalize(this.t('usos por colaborador'))}
                        parse={v => formatOnlyNumberInput(v)}
                        readOnly={view && !editing}
                        disabled={values.unlimited_user_uses === true}
                        className="mr-4"
                      />
                      <Field
                        name="user_uses_interval"
                        component={SelectInput}
                        placeholder={capitalize(this.t('interval'))}
                        label={capitalize(this.t('interval between uses'))}
                        options={possibleIntervals}
                        empty={capitalize(this.t('without interval'))}
                        readOnly={view && !editing}
                        disabled={values.unlimited_user_uses === true}
                      />
                      <WhenFieldChangesSetAnotherField set="user_uses" field="unlimited_user_uses" becomes="1" to="0" />
                      <WhenFieldChangesSetAnotherField
                        set="user_uses_interval"
                        field="unlimited_user_uses"
                        becomes="1"
                        to="0"
                      />
                    </div> */}
                    <hr />
                    <h2 className="text-xl px-3 mb-3">{capitalize(this.t('availability period'))}</h2>
                    <div className="flex ml-8">
                      <Field
                        name="start_date"
                        component={DatepickerInput}
                        placeholder={this.t('Start date')}
                        label={this.t('Start date')}
                        readOnly={view && !editing}
                        className="pr-3"
                      />
                      <Field
                        name="end_date"
                        component={DatepickerInput}
                        placeholder={this.t('End date')}
                        label={this.t('End date')}
                        readOnly={view && !editing}
                      />
                    </div>
                  </div>
                </FormLayout>
              );
            }}
          </Form>
        </PanelWindow>
      </LayoutWithSidebar>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    benefit: state.benefits.current,
    businesses: state.businesses.list,
    business: state.businesses.current,
    categories: state.categories.list,
    category: state.categories.current,
    files: state.files,
    user: state.users.current,
    settings: state.settings.list
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSaveOrUpdate: params => dispatch(benefitsActions.saveOrUpdate(params)),
    onGetBenefit: id => dispatch(benefitsActions.get(id)),
    onGetBenefitCategories: params => dispatch(categoriesActions.getAll(params)),
    onGetBusinesses: id => dispatch(businessesActions.getAll(id)),
    onGetBusiness: id => dispatch(businessesActions.get(id)),
    onGetSettings: params => dispatch(settingsActions.getAll(params)),
    onGetTypeName: id => dispatch(categoriesActions.get(id)),
    onGetUser: params => dispatch(usersActions.get(params)),
    onClearCurrent: () => dispatch(benefitsActions.clearCurrent()),
    onPictureUpload: params => dispatch(filesActions.upload(params))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Benefit));
